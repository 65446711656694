import React from 'react';
import './contact.css'

const Contact: React.FC = () => {
  return (
    <div className="contact section">
      <a href="email:nele.torm@gmail.com">@Torm</a>
    </div>
  )
}

export default Contact;

