import React from 'react';
import './schedule.css';

const Schedule: React.FC = () => {
  return (
    <div className="schedule section">
      <hr />
      <div>
        <h3 className="center">Ajakava</h3>
        <ul>
          <li><b>14:45</b> KOGUNEMINE <p><a rel="noopener noreferrer" target="_blank" href="https://goo.gl/maps/AD7yPPS5LbYcgBNYA">Paide raekoda II korrus (Keskväljak 14, Paide)</a></p></li>
          <li><b>15:00</b> TSEREMOONIA <p>Tseremoonia algusaeg hilineb.</p></li>
          <li>PRUUTPAARI PILDISTAMINE</li>
          <li>PULMARONG PEOPAIKA <p> sihtkoht <a rel="noopener noreferrer" target="_blank" href="https://goo.gl/maps/aX7uEmMd8GqkPNBG9">Hindreku talu, Valgma küla, Paide vald</a></p></li>
          <li>MAJUTUMINE <span className="amp"></span> KOGUNEMINE PEOSAALI</li>
          <li>ÕNNITLEMINE <span className="amp"></span> PILDISTAMINE</li>
          <li><b>18:00</b> PEO ALGUS</li>
          <li><b>04:00</b> Kellakeeramine ehk pidu kestab tund aega kauem</li>
        </ul>
        <p className="center">Lilli palume mitte tuua.</p>
        <hr />
        <h3 className="center mb">27.10 hommik</h3>
        <ul>
          <li><b>Alates 09:00</b> Hommikusöök <p>asukoht: Magamine 2</p></li>
          <li><b>12:00</b> Hiliseim lahkumine</li>
        </ul>
      </div>
    </div>
  )
}

export default Schedule;
