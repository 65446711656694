import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Contact from './contact/Contact';
import Food from './food/Food';
import Housing from './housing/Housing';
import Info from './info/Info';
import Invitation from './invitation/Invitation';
import MapComponent from './map/Map';
import Menu from './menu/Menu';
import Schedule from './schedule/Schedule';
import Separator from './Separator';
import Table from './table/table';
import AlcoComponent from './alco/Alco';

const reservedNames = ['schedule', 'map', 'contact', 'table', 'housing', 'info', 'food', 'vein', 'viin', 'rumm'];

class App extends React.Component<{}, {names: string[]}> {

  constructor(params: any) {
    super(params);

    let paths = window.location.pathname.split('/').filter(x => x.length > 0 && reservedNames.indexOf(x) < 0).map(x => decodeURIComponent(x));
    if (paths.length > 0) {
      localStorage.setItem('who', JSON.stringify(paths));
      window.history.replaceState({}, document.title, "/");
    }
    else {
      const serialized = localStorage.getItem('who');
      if (serialized && serialized !== null) {
        paths = JSON.parse(serialized);
      }
    }

    this.state = {
      names: paths
    }
  }

  render() {
    return (
      <div className="app">
        <img alt="" className="hands" src="/handsc.jpg" />
        <Router>
          <div>
            <Menu />
            <Separator />
            <Route exact path="/" component={Invitation} />
            <Route path="/schedule" component={Schedule} />
            <Route path="/map" component={MapComponent} />
            <Route path="/contact" component={Contact} />
            <Route path="/table" component={Table} />
            <Route path="/housing" component={Housing} />
            <Route path="/info" component={Info} />
            <Route path="/food" component={Food} />
            <Route path="/viin" component={AlcoComponent} />
            <Route path="/vein" component={AlcoComponent} />
            <Route path="/rumm" component={AlcoComponent} />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
