import React from 'react';
import './map.css';

const MapComponent: React.FC = () => {
  return (
    <div className="map section">
      <a rel="noopener noreferrer" target="_blank" href="https://goo.gl/maps/aX7uEmMd8GqkPNBG9">Hindreku talu, Valgma küla, Paide vald</a>
      <img alt="Kaart" src="/map.png" />
    </div>
  )
}

export default MapComponent;

