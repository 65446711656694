import React from 'react';
import './food.css';

const Food: React.FC = () => {
  return (
    <div className="food section">
      <div className="foodi">
        <img src="/knife.png" />
        <div>
          <span><b>Pearoog</b></span>
          <ul>
            <li>Seapraad</li>
            <li>Ahjukartul</li>
            <li>Kaste</li>
            <li>Hapukapsas</li>
            <li>Lastele kanapasta</li>
          </ul>
          <span><b>Külmlaud</b></span>
          <ul>
            <li>Kartulisalat</li>
            <li>Makaronisalat</li>
          </ul>
          <span><b>Suupisted</b></span>
          <ul>
            <li>Täidetud singirullid</li>
            <li>Kanasnäkk</li>
            <li>Kala punases marinaadis</li>
            <li>Küüslauguvorstike</li>
            <li>Suitsuvorst</li>
            <li>Suitsusink</li>
            <li>Juust</li>
            <li>Heeringas sibula ja hapukoorega</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Food;

