import React from 'react';
import './info.css';

const Info: React.FC = () => {
  return (
    <div className="info section">
      <ul>
        <li>Lilli palume <b>mitte tuua.</b> </li>
        <li>Tulemisest/mitte tulemisest teatada <b>hiljemalt 15.08.2019</b> e-mailile.</li>
        <li><b>Majutuskohti</b> jätkub enamikule, aga mitte kõigile.<p>Soovist teatamisel ole kiire!</p></li>
        <li><b>Kontakt ja lisainfo: pulm@abel.ee</b></li>
        <li>Pulmaisa <b>Karl Pütsep</b> +372 55 500 800 info@vennadvahid.eu</li>
        <li>Sotsiaalmeedias #nelejahenri</li>
        <li>Pulmas võib pilte teha ja postitada. Tähtsatel hetkedel laseme professionaalidel teha tööd, paneme enda telefonid käest ning naudime vaadet</li>
      </ul>
    </div>
  )
}

export default Info;

