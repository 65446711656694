import React from 'react';
import './invitation.css';

const singleBody = (
  <div className="body">
    <div className="col">
      <div className="verse">
        <span>Vabaabielu paha,</span>
        <span>lõppema küll peab see jama.</span>
        <span>Pulmakellad helisegu,</span>
        <span>algamas on päris elu.</span>
      </div>
      <div className="verse">
        <span>Hoolega nüüd loe neid ridu:</span>
        <span>26. oktoober toimub pidu,</span>
        <span>14:45 ole platsis</span>
        <span>Paide raekoja platsil.</span>
      </div>
      <div className="verse">
        <span>Loodame, et saad tulla,</span>
        <span>ootame Sind väga külla!</span>
        <span>Saabumisest ruttu teata,</span>
        <span>et pidu kulgeks veata.</span>
      </div>
    </div>
    <div className="col">
      <div className="verse">
        <span>Süüa juua meil on palju,</span>
        <span>muusikagi väga valju.</span>
        <span>Mõned saavad magadagi,</span>
        <span>soovist teatamisel ole tragi.</span>
      </div>
      <div className="verse">
        <span>Pulmareisiks kohvrid valmis,</span>
        <span>aga reis on päris kallis...</span>
        <span>Tahaks sooja minna talvel,</span>
        <span>kink ümbrikusse paki - meie palvel.</span>
      </div>
      <div className="verse img">
        <img alt="" src="rings.png" />
        <br />
        <span>Henri <span className="amp"></span> Nele</span>
      </div>
    </div>
  </div>)

  const multipleBody = (
  <div className="body">
    <div className="col">
      <div className="verse">
        <span>Vabaabielu paha,</span>
        <span>lõppema küll peab see jama.</span>
        <span>Pulmakellad helisegu,</span>
        <span>algamas on päris elu.</span>
      </div>
      <div className="verse">
        <span>Hoolega nüüd lugege neid ridu:</span>
        <span>26. oktoober toimub pidu,</span>
        <span>14:45 olge platsis</span>
        <span>Paide raekoja platsil.</span>
      </div>
      <div className="verse">
        <span>Loodame, et saate tulla,</span>
        <span>ootame Teid väga külla!</span>
        <span>Saabumisest ruttu teata,</span>
        <span>et pidu kulgeks veata.</span>
      </div>
    </div>
    <div className="col">
      <div className="verse">
        <span>Süüa juua meil on palju,</span>
        <span>muusikagi väga valju.</span>
        <span>Mõned saavad magadagi,</span>
        <span>soovist teatamisel olge tragid.</span>
      </div>
      <div className="verse">
        <span>Pulmareisiks kohvrid valmis,</span>
        <span>aga reis on päris kallis...</span>
        <span>Tahaks sooja minna talvel,</span>
        <span>kink ümbrikusse pakkige - meie palvel.</span>
      </div>
      <div className="verse img">
        <img alt="" src="rings.png" />
        <br />
        <span>Henri <span className="amp"></span> Nele</span>
      </div>
    </div>
  </div>)

const Invitation: React.FC = () => {
  let names = JSON.parse(localStorage.getItem('who') as string);
  const media = (<div className="media">
    <br />
    <a className="ml" href="https://1drv.ms/u/s!AvmZsO3K4VoMgvNnrk9xJ1ELAb7AWQ?e=sUpa0h">Pulmapildid</a>
    <br />
    <a target="_blank" href="https://1drv.ms/u/s!AvmZsO3K4VoMgvNnrk9xJ1ELAb7AWQ?e=sUpa0h"><img src="/us.jpg" alt="Pulmad" className="mw560"/></a>
    <p className="ml">Lühike pulmavideo</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/c_PKeSiUV6U" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
    <p className="ml">Pikk pulmavideo</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/T1jRPcmOMus" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
  </div>);
  if (!names || names.length === 0) {
    return (
      <div className="section invitation">
        {media}
        <div className="header">
          <p className="what">Pulmakutse</p>
        </div>
        {singleBody}
      </div>);
  }

  if (names.length === 1) {
    return (
    <div className="section invitation">
      {media}
      <div className="header">
        <p className="what">Pulmakutse</p>
        <span className="who">{names[0]}</span>
      </div>
      {singleBody}
    </div>);
  }
  else {
    return (
    <div className="section invitation">
      {media}
      <div className="header">
        <p className="what">Pulmakutse</p>
        <span className="who">{names[0]} <span className="amp"></span> {names[1]}</span>
      </div>
      {multipleBody}
    </div>);
  }
}

export default Invitation;
