import React from 'react';
import './table.css';

const Table: React.FC = () => {

  const data = [
    [['Pulmaisad', 'DJ Tõnis'], ['Video Sille', 'Kristi R'], ['Video Eno', 'Lauri'], ['Foto Anni', 'Magnar'], ['Deisy', 'Kristiina'], ['Marko N', 'Merlin']],
    [['Timo', 'Miguel'], ['Kaur', 'Christo'], ['', 'Raino'], ['', 'Janno'], ['', 'Vassili'], ['', 'Kertu K'], ['', 'Riine']],
    [['', 'Taigo'], ['Andris', 'Robert'], ['Grete', 'Vaimar'], ['', 'Ruth'], ['', 'Henri'], ['', 'Nele']],
    [['', 'Evi'], ['', 'Maiken'], ['Aare', 'Marta'], ['Arti', 'Kertu G'], ['Delia', 'Roman'], ['Rosanna', 'Richard']],
    [['Cäty', 'Ilknur'], ['Christa', 'Pelin'], ['Jonatan', 'Elcin'], ['Eli Samuel', 'Kristi T'], ['Kaire H', 'Jonas'], ['Ene', 'Tiina']],
    [['Mare', 'Aimar'], ['Viorica', 'Agnes'], ['Uko', 'Willem'], ['', 'Anne'], ['', 'Ly'], ['', 'Margus']],
    [['', 'Marko K'], ['', 'Elis'], ['', 'Ainiki'], ['Ida Maria', 'Veevo'], ['Siim N', 'Kaarel'], ['Kardo', 'Roger'], ['Kim', 'Kersti']],
    // [['', ''], ['', ''], ['', ''], ['', ''], ['', ''], ['', '']]
  ];

  let names = JSON.parse(localStorage.getItem('who') as string) as string[];
  if (undefined === names || null === names) {
    names = [];
  }

  const tables = data.map((t, i) => {
    return (<table>
      <thead>
        <tr>
          <td className="table-header" colSpan={2}>{i + 1}</td>
        </tr>
      </thead>
      <tbody>
        {
          t.map(row => {
            const h1 = names.findIndex(x => row[0].toLowerCase() === x.toLowerCase()) >= 0;
            const h2 = names.findIndex(x => row[1].toLowerCase() === x.toLowerCase()) >= 0;
            return <tr>
              <td className={h1 ? 'highlight': ''}>{row[0]}</td>
              <td className={h2 ? 'highlight': ''}>{row[1]}</td>
            </tr>
          })
        }
      </tbody>
    </table>)
  });

  return (
    <div className="table section">
      <div className="left">
        {tables}
      </div>
      <div className="parent right">
        <div className="div1 top"><span>1</span></div>
        <div className="div2 top"><span>2</span></div>
        <div className="div3 reverse"><span>3</span></div>
        <div className="div4 reverse"><span>4</span></div>
        <div className="div5 reverse"><span>5</span></div>
        <div className="div6 reverse"><span>6</span></div>
        <div className="div7"><span>7</span></div>
        {/*<div className="div8 "><span>8</span></div> */}
      </div>
    </div>
  )
}

export default Table;

