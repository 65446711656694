import React from 'react';
import './separator.css'

const Separator: React.FC = () => {
  return (
    <div className="separator">
      <hr className="left" />
      <img alt="" src="/square.svg" />
      <hr className="right" />
    </div>
  )
}

export default Separator;
