import React from 'react';

(String.prototype as any).replaceAll = function(search: string, replacement: string) {
    var target = this;
    return target.split(search).join(replacement);
};

function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const AlcoComponent: React.FC = () => {
    const loc = (document.location.pathname as any).replaceAll('/', '');
    if (loc === 'rumm') {
        const imageNr = getRandomInt(1, 4);
        return <div className="alc"><img alt="Rumm" src={'/rumm/' + imageNr + '.jpg'} /></div>
    }
    else if (loc === 'viin') {
        const imageNr = getRandomInt(1, 7);
        return <div className="alc"><img alt="Viin" src={'/viin/' + imageNr + '.jpg'} /></div>
    }
    if (loc === 'vein') {
        const imageNr = getRandomInt(1, 5);
        return <div className="alc"><img alt="Vein" src={'/vein/' + imageNr + '.jpg'} /></div>
    }
    return (
        <div className="alc">
        
        </div>
    )
}

export default AlcoComponent;


