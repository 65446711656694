import React from 'react';
import { NavLink } from 'react-router-dom';
import './menu.css';

const Menu: React.FC = () => {
  return (
    <div className="menu">
      <NavLink activeClassName="is-active" exact={true} to="/">Kutse</NavLink>
      <NavLink activeClassName="is-active" to="/schedule">Ajakava</NavLink>
      <NavLink activeClassName="is-active" to="/info">Info</NavLink>
      <NavLink activeClassName="is-active" to="/map">Kaart</NavLink>
      <NavLink activeClassName="is-active" to="/table">Lauaplaan</NavLink>
      <NavLink activeClassName="is-active" to="/housing">Majutus</NavLink>
      <NavLink activeClassName="is-active" to="/food">Menüü</NavLink>
    </div>
  )
}

export default Menu;

