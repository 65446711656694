import React from 'react';
import './housing.css';

const Housing: React.FC = () => {
  const majutus1 = ['Kristi Lauri', 'Merlin Miguel', 'Christo Vassili', 'Robert Andris',
   'Riine Taigo Grete Kertu','Deisy Marko Kaur Magnar', 'Kristiina',
    'Raino Janno', 'Arti Delia', 'Kaarel Timo', 'Richard', '', 'Elis Marko', 'Ainiki Veevo']
  const majutus2 = ['Henri Nele', 'Vaimar Ruth', 'Agnes Willem Roger Kersti', 'Evi Cäty Maiken Roman', 'Ilknur Elcin Pelin', 'Tiina Aimar Kaire Eli Samuel', 'Kristi Jonas', 'Christa Jonatan', 'Ene Anne', 'Ly Margus'];

  let names = JSON.parse(localStorage.getItem('who') as string) as string[];
  if (undefined === names || null === names) {
    names = [];
  }
  const roomWrapper = (who: string, i: number) => {
    const splitted = who.split(' ');
    const hightLight = names.findIndex(x => splitted.findIndex(x2 => x2.toLowerCase() === x.toLowerCase()) >= 0) >= 0;
    return (<tr key={who + i} className={hightLight ? 'highlight' : ''}>
      <td>{i + 1}</td>
      <td>{who}</td>
    </tr>);
  }
  const rooms = majutus1.map(roomWrapper);
  const rooms2 = majutus2.map(roomWrapper);
  return (
    <div className="housing section">
      <table>
        <tbody>
          <tr>
            <th>Toa nr</th>
            <th>Majutus 1</th>
          </tr>
          {rooms}
        </tbody>
      </table>

      <table>
        <tbody>
          <tr>
            <th>Toa nr</th>
            <th>Majutus 2</th>
          </tr>
          {rooms2}
        </tbody>
      </table>
    </div>
  )
}

export default Housing;


